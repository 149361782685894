import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { formatDate, formatTime } from "../../utils/commonFunctions";
import { ArrowDropDown } from "@mui/icons-material";
import { baseUrl } from "../../utils/BaseUrl";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "hidden",
  " h5": {
    textTransform: "capitalize",
  },
}));
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  "& > h5:first-of-type": {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: "96vw",
  margin: "0 !important",
  paddingBottom: "1rem",
  paddingLeft: "1rem",
  paddingRight: "1rem",
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: "0 16px !important",
  minHeight: "auto !important",
  "> div:first-of-type": {
    margin: "0 !important",
  },
  "& h4": {
    fontWeight: "bold !important",
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: "0 16px !important",
}));

function MatchDetails() {
  const { id } = useParams();
  const [match, setMatch] = useState();

  useEffect(() => {
    axios
      .get(`${baseUrl}/${id}`)
      .then((res) => {
        console.log(res.data);
        setMatch(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <StyledContainer>
      {match && (
        <>
          <StyledBox>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {match.team1}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              &nbsp;vs
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {match.team2}
            </Typography>
          </StyledBox>

          <StyledAccordion defaultExpanded={true}>
            <StyledAccordionSummary
              expandIcon={<ArrowDropDown />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h4">Venue</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <StyledBox>
                <Typography variant="h5">Venue : </Typography>
                <Typography variant="h5">{match.venue} </Typography>
              </StyledBox>
              <StyledBox>
                <Typography variant="h5">Date : </Typography>
                <Typography variant="h5">
                  {formatDate(match.date)}
                  {formatTime(match.date)}
                </Typography>
              </StyledBox>
              <StyledBox>
                <Typography variant="h5">Format : </Typography>
                <Typography variant="h5">{match.format}</Typography>
              </StyledBox>
              <StyledBox>
                <Typography variant="h5">Tournament : </Typography>
                <Typography variant="h5">{match.tournament}</Typography>
              </StyledBox>
            </StyledAccordionDetails>
          </StyledAccordion>

          <StyledAccordion defaultExpanded={true}>
            <StyledAccordionSummary
              expandIcon={<ArrowDropDown />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h4">Pitch Report</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Box>
                <StyledBox>
                  <Typography variant="h5">Pitch : </Typography>
                  <Typography variant="h5">{match.pitchType}</Typography>
                </StyledBox>
                <StyledBox>
                  <Typography variant="h5">Grass covering : </Typography>
                  <Typography variant="h5">{match.grassLevel}</Typography>
                </StyledBox>
                <StyledBox>
                  <Typography variant="h5">Grass level : </Typography>
                  <Typography variant="h5">{match.grassLevel}</Typography>
                </StyledBox>

                <StyledBox>
                  <Typography variant="h5">Pitch moisture: </Typography>
                  <Typography variant="h5">{match.moisture}</Typography>
                </StyledBox>
                <StyledBox>
                  <Typography variant="h5">favourable for : </Typography>
                  <Typography variant="h5">{match.favourableFor}</Typography>
                </StyledBox>
              </Box>
            </StyledAccordionDetails>
          </StyledAccordion>

          {/* FIRST INNINGS INSIGHTS */}
          <StyledAccordion defaultExpanded={true}>
            <StyledAccordionSummary
              expandIcon={<ArrowDropDown />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h4">First Innings Insights</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Box>
                <StyledBox>
                  <Typography variant="h5">Power play :</Typography>
                  <Typography variant="h5">{match.powerPlay}</Typography>
                </StyledBox>
                <StyledBox>
                  <Typography variant="h5">
                    First Innings par score :
                  </Typography>
                  <Typography variant="h5">
                    {match.firstInningsParScore}
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography variant="h5">Middle phase : </Typography>
                  <Typography variant="h5">{match.middlePhase}</Typography>
                </StyledBox>

                <StyledBox>
                  <Typography variant="h5">Death Overs : </Typography>
                  <Typography variant="h5">{match.deathOvers}</Typography>
                </StyledBox>
              </Box>
            </StyledAccordionDetails>
          </StyledAccordion>

          {/* Players suited for the pitch */}
          <StyledAccordion defaultExpanded={true}>
            <StyledAccordionSummary
              expandIcon={<ArrowDropDown />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h4">Players suited for the pitch</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Box>
                <StyledBox>
                  <Typography variant="h5">Batters :</Typography>
                  <Typography variant="h5">{match.pitchType}</Typography>
                </StyledBox>
                <StyledBox>
                  <Typography variant="h5">Bowlers :</Typography>
                  <Typography variant="h5">{match.grassLevel}</Typography>
                </StyledBox>
              </Box>
            </StyledAccordionDetails>
          </StyledAccordion>

          {/* Players suited for the pitch */}
          <StyledAccordion defaultExpanded={true}>
            <StyledAccordionSummary
              expandIcon={<ArrowDropDown />}
              aria-controls="panel2-content"
              id="panel2-header"
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="h4">Expert Insights</Typography>
              </Box>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Box>
                {match?.expertInsights?.map((item, i) => {
                  return (
                    <StyledBox key={i}>
                      <Typography variant="h5">Insight - {i + 1} :</Typography>
                      <Typography variant="h5">{item}</Typography>
                    </StyledBox>
                  );
                })}
                {/* <StyledBox>
                  <Typography variant="h5">Insight -2 :</Typography>
                  <Typography variant="h5">{match.grassLevel}</Typography>
                </StyledBox> */}
              </Box>
            </StyledAccordionDetails>
          </StyledAccordion>
        </>
      )}
    </StyledContainer>
  );
}

export default MatchDetails;
