import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { debounce, formatTime } from "../../utils/commonFunctions";
import { baseUrl } from "../../utils/BaseUrl";

function Matches({ isAdmin }) {
  const [value, setValue] = useState(0);
  const [team, setTeam] = useState("");
  const [venue, setVenue] = useState("");
  const [tournament, setTournament] = useState("");
  const [format, setFormat] = useState("");
  const [date, setDate] = useState();
  const [open, setOpen] = React.useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState("");

  const [matches, setMatches] = useState([]);

  const navigate = useNavigate();

  const formatOptions = [
    { value: "test", label: "Test" },
    { value: "odi", label: "ODI" },
    { value: "t20", label: "T20" },
  ];

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleReset = () => {
    setTeam("");
    setVenue("");
    setTournament("");
    setDate();
    setFormat("");
  };

  const fetchMatches = useCallback(
    debounce(() => {
      const params = new URLSearchParams();

      const status =
        value === 0 ? "upcoming" : value === 1 ? "live" : "completed";
      params.append("status", status);
      if (team) params.append("team", team);
      if (venue) params.append("venue", venue);
      if (tournament) params.append("tournament", tournament);
      if (date) params.append("date", date.toISOString());
      if (format) params.append("format", format);

      axios
        .get(`${baseUrl}?${params.toString()}`)
        .then((res) => {
          console.log(res.data);
          setMatches(res.data);
        })
        .catch((err) => console.log(err));
    }, 1000),
    [team, venue, tournament, format, date, value]
  );

  useEffect(() => {
    fetchMatches();
  }, [team, venue, tournament, format, date, value, fetchMatches]);

  const handleDelete = (matchId) => {
    setOpen(true);
    setSelectedMatchId(matchId);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMatchId("");
  };

  const handleDeleteMatch = () => {
    console.log("first");
    axios
      .delete(`${baseUrl}/${selectedMatchId}`)
      .then((res) => {
        console.log(res.data);
        fetchMatches();
        handleClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ border: 1, borderColor: "divider" }}>
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          <Tab label="Upcoming" />
          <Tab label="Live" />
          <Tab label="Completed" />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          mt: 1,
        }}
      >
        <Typography variant="span" sx={{ mr: 1 }}>
          Filter By :{" "}
        </Typography>
        <TextField
          placeholder="Team.."
          size="small"
          value={team}
          onChange={(e) => setTeam(e.target.value)}
        />
        <TextField
          placeholder="Venue.."
          size="small"
          value={venue}
          onChange={(e) => setVenue(e.target.value)}
        />
        <TextField
          placeholder="Tournament"
          size="small"
          value={tournament}
          onChange={(e) => setTournament(e.target.value)}
        />
        <TextField
          select
          label="Format"
          sx={{ minWidth: "7rem" }}
          size="small"
          value={format}
          onChange={(e) => setFormat(e.target.value)}
        >
          {formatOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={date}
            onChange={(newValue) => {
              setDate(dayjs(newValue));
            }}
            sx={{
              height: "40px !important",
              overflow: "hidden",
              "& > *": {
                height: "inherit",
              },
            }}
          />
        </LocalizationProvider>
        <Button
          disabled={team || venue || tournament || format ? false : true}
          onClick={handleReset}
        >
          Reset
        </Button>
      </Box>
      <Box>
        {matches.map((match, i) => {
          return (
            <Box
              key={i}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                border: "1px solid #bfbfbf",
                padding: "1rem",
                margin: "1rem auto",
                width: "80vw",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              <Box
                onClick={() => navigate(`/match-details/${match._id}`)}
                sx={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Typography variant="h4" title={match.team1}>
                    {match.team1.substring(0, 3)}
                  </Typography>
                  <Typography variant="h5">vs</Typography>
                  <Typography variant="h4" title={match.team2}>
                    {match.team2.substring(0, 3)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <Typography variant="h6">{formatDate(match.date)}</Typography> */}
                  {/* <Typography variant="h6" sx={{ margin: "0 1rem" }}>
                    |
                  </Typography> */}
                  <Typography variant="h6">{formatTime(match.date)}</Typography>
                  <Typography variant="h6" sx={{ margin: "0 1rem" }}>
                    |
                  </Typography>
                  <Typography variant="h6">T20I</Typography>
                  {/* <Typography variant="h6" sx={{ margin: "0 1rem" }}>
                    |
                  </Typography> */}
                  {/* <Typography variant="h6">{match.venue}</Typography> */}
                </Box>
              </Box>
              {isAdmin && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                >
                  {/* <Edit
                    onClick={() => {
                      navigate("/admin/edit");
                    }}
                  />
                  <Delete /> */}
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      navigate(`/admin/edit/${match._id}`);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleDelete(match._id)}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure, you want to delete ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action can't be reversable
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteMatch} color="error">
            Sure
          </Button>
          <Button onClick={handleClose} color="info" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Matches;
