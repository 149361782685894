import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

function Header({ isAdmin }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Typography variant="h3" onClick={() => navigate("/")}>
        <img
          src="/logo.jpeg"
          style={{ height: "50px", marginLeft: "1rem" }}
          alt="logo"
        />
      </Typography>
      {isAdmin && (
        <Button
          size="small"
          variant="outlined"
          onClick={() => navigate("/admin/addmatch")}
        >
          Add Match
        </Button>
      )}
    </Box>
  );
}

export default Header;
