import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React, { useState } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import axios from "axios";
import { baseUrl } from "../../utils/BaseUrl";

function AddMatch() {
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [venue, setVenue] = useState("");
  const [tournament, setTournament] = useState("");
  const [format, setFormat] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [pitchType, setPitchType] = useState("");
  const [grassLevel, setGrassLevel] = useState("");

  const [moistureContent, setMoistureContent] = useState("");
  const [deathOvers, setDeathOvers] = useState("");
  const [favFor, setFavFor] = useState("");

  const [powerPlay, setPowerPlay] = useState("");
  const [firstInningsPar, setFirstInningsPar] = useState(0);
  const [middlePhase, setMiddlePhase] = useState("");
  const [battersSuitedForPitch, setBattersSuitedForPitch] = useState("");
  const [bowlersSuitedForPitch, setBowlersSuitedForPitch] = useState("");
  const [expertInsights, setExpertInsights] = useState([]);
  const [expertInsight, setExpertInsight] = useState("");

  const formatOptions = [
    { value: "test", label: "Test" },
    { value: "odi", label: "ODI" },
    { value: "t20", label: "T20" },
  ];

  const handleSubmit = () => {
    axios.post(baseUrl, {
      team1,
      team2,
      venue,
      date,
      tournament,
      format,
      pitchType,
      grassLevel,
      moisture: moistureContent,
      favourableFor: favFor,
      powerPlay,
      deathOvers,
      firstInningsParScore: firstInningsPar,
      middlePhase,
      typeOfBatsmenSuited: battersSuitedForPitch,
      typeOfBowlersSuited: bowlersSuitedForPitch,
      expertInsights,
    });
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Typography variant="h4">Add Match</Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <Box
          sx={{
            border: "1px solid #000",
            width: "fit-content",
            padding: "1rem",
          }}
        >
          <Typography variant="h5">Match details : </Typography>
          <Box>
            <Typography variant="span">Team-1 : </Typography>
            <TextField
              required
              placeholder="Team1"
              size="small"
              value={team1}
              onChange={(e) => setTeam1(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Team-2 : </Typography>
            <TextField
              required
              placeholder="Team2"
              size="small"
              value={team2}
              onChange={(e) => setTeam2(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Venue : </Typography>
            <TextField
              required
              placeholder="Venue"
              size="small"
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Tournament : </Typography>
            <TextField
              required
              placeholder="Tournament"
              size="small"
              value={tournament}
              onChange={(e) => setTournament(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Format : </Typography>
            <TextField
              select
              // label="Format"
              sx={{ minWidth: "7rem" }}
              size="small"
              value={format}
              onChange={(e) => setFormat(e.target.value)}
            >
              {formatOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <Typography variant="span">Date : </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={date}
                onChange={(newValue) => {
                  setDate(dayjs(newValue));
                }}
                sx={{
                  height: "40px !important",
                  overflow: "hidden",
                  "& > *": {
                    height: "inherit",
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid #000",
            width: "fit-content",
            padding: "1rem",
          }}
        >
          <Typography variant="h5">Pitch details : </Typography>
          <Box>
            <Typography variant="span">Pitch Type : </Typography>
            <TextField
              size="small"
              placeholder="Pitch type"
              value={pitchType}
              onChange={(e) => setPitchType(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Grass Level : </Typography>
            <TextField
              size="small"
              placeholder="Grass Level"
              value={grassLevel}
              onChange={(e) => setGrassLevel(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Moisture Content : </Typography>
            <TextField
              size="small"
              placeholder="Moisture content"
              value={moistureContent}
              onChange={(e) => setMoistureContent(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Favourable for : </Typography>
            <TextField
              size="small"
              placeholder="Favourable for"
              value={favFor}
              onChange={(e) => setFavFor(e.target.value)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #000",
            width: "fit-content",
            padding: "1rem",
          }}
        >
          <Typography variant="h5">First Innings insights : </Typography>
          <Box>
            <Typography variant="span">Power play : </Typography>
            <TextField
              size="small"
              placeholder="Power play"
              value={powerPlay}
              onChange={(e) => setPowerPlay(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">First Innings par score : </Typography>
            <TextField
              size="small"
              value={firstInningsPar}
              onChange={(e) => setFirstInningsPar(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Middle phase : </Typography>
            <TextField
              size="small"
              value={middlePhase}
              onChange={(e) => setMiddlePhase(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Death Overs : </Typography>
            <TextField
              size="small"
              value={deathOvers}
              onChange={(e) => setDeathOvers(e.target.value)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #000",
            width: "fit-content",
            padding: "1rem",
          }}
        >
          <Typography variant="h5">Players suited for the pitch : </Typography>
          <Box>
            <Typography variant="span">Batters : </Typography>
            <TextField
              size="small"
              value={battersSuitedForPitch}
              onChange={(e) => setBattersSuitedForPitch(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="span">Bowlers : </Typography>
            <TextField
              size="small"
              value={bowlersSuitedForPitch}
              onChange={(e) => setBowlersSuitedForPitch(e.target.value)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #000",
            width: "fit-content",
            padding: "1rem",
          }}
        >
          <Typography variant="h5">Expert Insights : </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {expertInsights.map((item, i) => {
                return (
                  <Box key={item + i} sx={{ display: "flex" }}>
                    <Typography variant="span">Insight {i + 1} :</Typography>
                    <TextField
                      size="small"
                      value={item}
                      onChange={(e) =>
                        setExpertInsights([e.target.value, ...expertInsights])
                      }
                    />
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="span">
                Insight {expertInsights.length + 1} :
              </Typography>
              <TextField
                size="small"
                value={expertInsight}
                onChange={(e) => setExpertInsight(e.target.value)}
              />
              <AddCircleOutline
                onClick={() => {
                  setExpertInsights([...expertInsights, expertInsight]);
                  setExpertInsight("");
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Button onClick={handleSubmit} variant="contained">
        Create Match
      </Button>
    </Box>
  );
}

export default AddMatch;
