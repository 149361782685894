import * as React from "react";

import Box from "@mui/material/Box";
import Matches from "./Matches";
import Header from "../Header";

const Home = () => {
 

  return (
    <Box sx={{ width: "100%" }}>
      <Header />
      
      <Box sx={{ width: "100%" }}>
        <Matches />
      </Box>
    </Box>
  );
};

export default Home;
