import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import "./index.css";
import Home from "./Components/Home/Home";
import MatchDetails from "./Components/Home/MatchDetails";
import AdminHome from "./Components/Admin/AdminHome";
import Header from "./Components/Header";
import AddMatch from "./Components/Admin/AddMatch";
import EditMatch from "./Components/Admin/EditMatch";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/match-details/:id",
    element: (
      <>
        <Header />
        <MatchDetails />,
      </>
    ),
  },
  {
    path: "/admin",
    element: (
      <>
        <Header isAdmin={true} />
        <AdminHome />
      </>
    ),
  },
  {
    path: "/admin/addmatch",
    element: (
      <>
        <Header isAdmin={true} />
        <AddMatch />,
      </>
    ),
  },
  {
    path: "/admin/edit/:id",
    element: (
      <>
        <Header isAdmin={true} />
        <EditMatch />,
      </>
    ),
  },
]);

export default router;
